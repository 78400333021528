import React from 'react';
import { Layout, SEO, ContentSection } from '@egonoid/gatsby-theme-common';

import { graphql, Link } from 'gatsby';

import kebabCase from 'lodash/kebabCase';

const dateTimeOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: false,
  timeZone: 'Europe/Berlin',
};

interface IProps {
  data: any;
  location: any;
}

const VersionPage: React.FC<IProps> = ({
  data: {
    site: {
      buildTime,
      siteMetadata: { title },
    },
  },
  location,
}) => (
  <Layout location={location} title={title} showLogo={true}>
    <SEO title={title} />
    <ContentSection>
      <h1>Version Information</h1>
    </ContentSection>
    <ContentSection>
      <ul>
        <li>
          Last build:{' '}
          {new Intl.DateTimeFormat('de-DE', dateTimeOptions).format(Date.parse(buildTime))}
        </li>
      </ul>
    </ContentSection>
  </Layout>
);

export default VersionPage;

export const pageQuery = graphql`
  query {
    site {
      buildTime
      siteMetadata {
        title
      }
    }
  }
`;
